import navigation from './navigation';
import content from './content';
import tools from './tools';
import home from './home';
import products from './products';
import profile from './profile';
import favorites from './favorites';

export default {
  navigation,
  home,
  tools,
  content,
  products,
  profile,
  favorites,
};
