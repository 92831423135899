export default {
  user_stats_card: {
    explanations: {
      levels: [
        { interval: 'interval-1.key', name: 'name.key' },
        { interval: 'interval-2.key', name: 'name.key' },
      ],
    },
  },
};
