export default {
  comments: {
    sorts: [ 'Sort By Helpful', 'Sort By Newest', 'Sort By Oldest' ],
  },
  element_types: {
    video: {
      title: 'video.key',
      icon: 'mdi-play-box-outline',
      color: '#5CCEFF',
    },
    workbook: {
      title: 'workbook.key',
      icon: 'mdi-book-open-page-variant',
      responseLabel: 'Your Response',
      color: '#C288CB',
    },
    poll: {
      title: 'poll.key',
      icon: 'mdi-poll-box-outline',
      color: '#75CB99',
    },
    question: {
      title: 'question.key',
      icon: 'mdi-account-question',
      color: '#FFD176',
    },
    decision: {
      title: 'decision.key',
      icon: 'mdi-arrow-decision',
      color: '#FFA4A9',
      subtitle: 'Choose what you would like to do next',
    },
    submenu: {
      title: 'submenu.key',
      icon: 'mdi-menu-open',
      color: '#0098E1',
      subtitle: 'Choose what you would like to do next',
    },
    tool: {
      title: 'tool.key',
      icon: 'mdi-tools',
      color: '#9099AD',
    },
    mood: {
      title: 'mood_tracker.key',
      icon: 'mdi-emoticon-confused',
      color: '#FFB92E',
    },
    html: {
      title: 'note.key',
      icon: 'mdi-note',
      color: '#6A89FF',
    },
    goal: {
      title: 'goal_setter.key',
      icon: 'mdi-mountain',
      color: '#6CC5B9',
    },
    assessment: {
      title: 'assessment.key',
      icon: 'mdi-head-cog',
      color: '#FF8884',
    },
    thoughts: {
      title: 'thought_balancer.key',
      icon: 'mdi-weather-lightning-rainy',
      color: '#E1CCF1',
    },
    mcq: {
      title: 'mcq.key',
      icon: 'mdi-format-list-bulleted',
      color: '#E1CCF1',
    },
    msq: {
      title: 'msq.key',
      icon: 'mdi-format-list-bulleted-square',
      color: '#F2DDA2',
    },
    slider: {
      title: 'slider.key',
      icon: 'mdi-gauge',
      color: '#ff9800',
    },
    composition: {
      title: 'composition.key',
      icon: 'mdi-animation-outline',
      color: '#7E8DC1',
    },
    next_step: {
      title: 'next_step.key',
      icon: 'mdi-page-next',
      color: '#736010',
    },
    popup: {
      title: 'popup.key',
      icon: 'mdi-launch',
      color: '#CABE70',
    },
  },
};
